import React, {  useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";


import axios from "axios";
import { Center } from "./Center";

import { BASE_URL } from "../../../api/constants";
import {  BsXLg } from "react-icons/bs";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { userInfo } from "../../../store/userInfo";
import { TreeNodeType } from "../../TreeNode";
import { useFile } from "../../../hooks/useFile";
import { useHistory } from "react-router";
import { shouldShowFavorites } from "../../BurgerMenu";

interface UpdatesWrapperProps {
  isExiting: boolean;
}
export const Favorites = () => {
  const [isLoadingg, setIsLoadingg] = useState(false);

  const user = useRecoilValue(userInfo);

  const email = user.user.userPrincipalName;
  const [refresh, setRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [nodes, setNodes] = useState<TreeNodeType[]>([]);
  const [newNodes, setNewNodes] = useState<TreeNodeType[]>([]);
  const [loading, setLoading] = useState(true);
  const { treeFile, resetFile } = useFile();
  const history = useHistory();
  const setShouldShowFavorites = useSetRecoilState(shouldShowFavorites);
 
  const isOpen = useRecoilValue(shouldShowFavorites);
  const setIsOpen = useSetRecoilState(shouldShowFavorites);

  const [shouldRender, setShouldRender] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);


  useEffect(() => {
    setShouldRender(true)
  }, []);

  
  useEffect(() => {
    if (treeFile) {
      resetFile();
    }
  }, []);

  const HandleDeleteFile = (fileId: string) => {
    const deleteFileId = nodes.filter((node) => {
      return node._id !== fileId;
    });
    setNodes(deleteFileId);
  };

  useEffect(() => {
    setIsLoadingg(true);
    axios
      .post(
        `${BASE_URL}/likes/favoriteUser`,
        { email: email },
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result?.data) {
          setNodes(result.data.nodes);
          setIsLoadingg(false);
          setRefresh(false);
          setShowModal(false);
        }
      });
  }, [refresh]);

  useEffect(() => {
    nodes.map((node, i) => {
      if (node === null) {
        delete nodes[i];
      }
      console.log(nodes);
      setNewNodes(nodes);
    });
  }, [nodes]);
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(()=>{
    if(isFirstLoad && isOpen){
      setIsFirstLoad(false);
    }
  },[isOpen])

  return (
    <UpdatesWrapper
    className={isFirstLoad ? "no-animation" : isOpen ? "updates-open" : "updates-closed"}
  >
      <Top>
      <BsXLg onClick={handleClose} />
        <P>
          <Vector />
          <P0>מועדפים</P0>

          <Vector />
        </P>
      </Top>
      <Center
        setShowModal={setShowModal}
        showModal={showModal}
        setRefresh={setRefresh}
        isFavoritesLoading={isLoadingg}
        favorites={newNodes}
      />
      <Bottom />
    </UpdatesWrapper>
  );
};

const Top = styled.div`
  width: 100%;
  font-weight: 600;
  height: 5vh;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: row;
  background-color: #cdeaff;
  border: 1px solid rgba(80, 101, 132, 0.63);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
`;
const P = styled.div`
  color: #23344e;
  width: 80vw;
  font-family: "Assistant";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;

  text-align: center;
`;
const P0 = styled.p`
  margin-left: 1vw;
  margin-right: 1vw;
`;

const Bottom = styled.div`
  width: 99.2%;
  height: 1rem;
  display: flex;
  background-color: #cdeaff;
  border: 1px solid #cdeaff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
`;
const Vector = styled.div`
  width: 26px;
  height: 2px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-right: 10rem;
  background: linear-gradient(
    90deg,
    #23344e 93.72%,
    #ffffff 101.37%,
    #dfe1e5 101.38%,
    rgba(255, 255, 255, 0) 101.39%
  );
`;
const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
    height: 0;
  }
  25% {
    height: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    height: 17vh;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
    height: 17vh;

  }
  to {
    transform: translateY(-100%);
    opacity: 0;
    height: 0;
    }
`;

const UpdatesWrapper = styled.div`
  &.updates-open {
        animation: ${slideIn} 0.9s ease forwards;

  }
  &.updates-closed {
        animation: ${slideOut} 0.9s ease forwards;

  }
  &.no-animation {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 90vw;
  margin-bottom: 3vh;
  margin-top: 0.1vh;
  position: relative;
  &.notactive {
    display: flex;
    justify-content: center;
    padding: 0 0.1rem;
    &::before {
      content: "";
      background-color: gray;
      opacity: 0.4;
      z-index: 2;
      border-radius: 1rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
    &::after {
      position: absolute;
      align-self: center;
      text-align: center;
      content: "בקרוב..";
      font-size: 1.5rem;
      font-weight: 600;
      transform: rotate(340deg);
      color: black;
      z-index: 3;
    }
  }
`;
