import  { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import menu from "../../assets/Svgs/menu.svg";
import { BurgerMenu, unreadTiketsCount } from "../BurgerMenu";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import usePermissions from "../../hooks/usePermissions";
import axios from "axios";
import { BASE_URL } from "../../api/constants";


export const RightSide = () => {
  const userDetails = useRecoilValue(userInfo);

  const email = userDetails.user.userPrincipalName;
  const [numberNotification, setNumberNotification] = useState(0);
  const { isAdminUser, isSupportUser } = usePermissions();
  const [unreadCount,setUnreadCount] = useRecoilState(unreadTiketsCount);
  useEffect(() => {
    if (isSupportUser) {
      axios
        .post(
          `${BASE_URL}/contactTickets/getOpenTicketsCount`,
          {},
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          if (result.data.success) {
            setNumberNotification(result.data.tickets);
          }
        });
    } else {
      axios
        .post(
          `${BASE_URL}/contactTickets/getMyClosedTickets`,
          { email },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          if (result.data.success) {
            setNumberNotification(result.data.tickets);
          }
        });
    }
  }, [isSupportUser]);
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
 
    axios
    .post(`${BASE_URL}/contactTickets/unreadTiketsCount`, 
       {email}, 
       {
         withCredentials: true,
       })
     .then((result) => {
         if (result.data.success && result.data.count !== undefined) {
           setUnreadCount(result.data.count);
         }
       })
     .catch((error) => {
         console.error('Error fetching unread count:', error);
       });
   }, [setUnreadCount, email]);

  return (
    <RightSideWrapper>
         {unreadCount > 0 && (
          <Notification profileOpen={false}>
            <div>{unreadCount}</div>
          </Notification>
        )}  
        {numberNotification > 0 && isAdminUser && (
          <Notification profileOpen={false}>
            <div>{numberNotification}</div>
          </Notification>
        )}
      
      <LogoStyle
        src={menu}
        onClick={() => {
          setMenuOpen(true);
          console.log(true);
        }}
      />
      {
        <BurgerMenu
          numberNotification={numberNotification}
          open={menuOpen}
          setMenuOpen={setMenuOpen}
        />
      }

    </RightSideWrapper>
  );
};

const slideInFromLeft = keyframes`
  from {
    transform: translateX(100%);
    visibility: hidden;
  }
  to {
    transform: translateX(35%);
    visibility: visible;
  }
  `;

const slideInFromRight = keyframes`
  from {
    transform: translateX(35%);
    visibility: visible;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
`;

const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 0; */
  /* cursor: pointer; */
  width: 20vw;
  .slide-in-from-left {
    display: visible;
    animation: ${slideInFromLeft} 0.5s ease-in-out forwards;
  }
  .close {
    animation: ${slideInFromRight} 0.5s ease-in-out forwards;

    /* display: none; */
  }
`;

const LogoStyle = styled.img`
  width: 1.3rem;
  height: 1rem;
  margin: 0;
  margin-top: 0.2rem;
  margin-right: -0.5rem;
`;

interface NotificationProps {
  profileOpen: boolean;
}

export const Notification = styled.div<NotificationProps>`
  position: absolute;
  /* z-index: 999; */
  /* overflow: visible; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  border-radius: 50%;
  color: white;
  width: 1.3rem;
  height: 1.3rem;
  top: 1rem;
  left: 22.3rem;
  /* margin-left: 2.1rem;
  margin-top: -0.8rem; */
  opacity: ${(props) => (props.profileOpen ? "0" : "1")};
  transition: opacity 0.3s ease-in-out;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: -0.2rem;
  }
`;
