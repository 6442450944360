import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import search from "../../assets/Group 2.png";
import bell from "../../assets/Svgs/ball 1.svg";


import { unreadUpdatesCount } from "../BurgerMenu";
import usePermissions from "../../hooks/usePermissions";
import { BASE_URL } from "../../api/constants";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userInfo } from "../../store/userInfo";
import { shouldShowSearch, shouldShowUpdates } from "./Header";
import { useHistory } from "react-router";
import { IUpdate } from "../AdminsUpdates/Update";

export const LeftSide = () => {
  const { isAdminUser, isSupportUser } = usePermissions();
  const [numberNotification, setNumberNotification] = useState(undefined);
  const userDetails = useRecoilValue(userInfo);
  const setShouldShowSearch = useSetRecoilState(shouldShowSearch);
  const setShouldShowUpdates = useSetRecoilState(shouldShowUpdates);
  const history = useHistory();
  const [unreadCount,setUnreadCount] = useRecoilState(unreadUpdatesCount);
  const [realUpdates, setUpdates] = useState<IUpdate[]>([]);

  const email = userDetails.user.userPrincipalName;
  useEffect(() => {
    if (isSupportUser) {
      axios
        .post(
          `${BASE_URL}/contactTickets/getOpenTicketsCount`,
          {},
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          if (result.data.success) {
            setNumberNotification(result.data.tickets);
          }
        });
    } else {
      axios
        .post(
          `${BASE_URL}/contactTickets/getMyClosedTickets`,
          { email },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          if (result.data.success) {
            setNumberNotification(result.data.tickets);
          }
        });
    }
  }, [isSupportUser]);
useEffect(() => {
  axios
    .post(
      `${BASE_URL}/contactTickets/unreadUpdatesCount`,
      { email },
      {
        withCredentials: true,
      }
    )
    .then((result) => {
      if (result.data.success && result.data.count !== undefined) {
        // מקרה באייפון שבו נשמרות ההתרעות שלא נקראו אבל כבר נגמר להן התוקף
        // הכמות תשתנה באמת באתחול
        if(result.data.count>realUpdates.length)
        {
          setUnreadCount(realUpdates.length);

        }
        else{
          setUnreadCount(result.data.count);
        }
  
      }
    })
    .catch((error) => {
      console.error('Error fetching unread count:', error);
    });
}, [setUnreadCount, email]);




const resetUnreadNotifications = async () => {
  try {
    const result = await axios.post(
      `${BASE_URL}/contactTickets/resetUnreadUpdatesCount`,
      { email },
      { withCredentials: true }
    );

    if (result.data.success) {
      setUnreadCount(result.data.count);
    }
  } catch (error) {
    console.error('Error resetting unread notifications count:', error);
  }
};

  return (
    <LeftSideWarper>

      <LogoStyleBell
        src={bell}
        onClick={() => {
          history.push("/");
          setShouldShowUpdates((prev) => !prev);
          setShouldShowSearch(false);
          resetUnreadNotifications(); 
          setUnreadCount(0);
      }}
      />  
      {unreadCount > 0 && (
       <Notification profileOpen={false}>
          <div>{unreadCount }</div>
      </Notification>
      )}
      <LogoStyle
        src={search}
        onClick={() => {
          history.push("/");
          setShouldShowSearch((prev) => !prev);
          setShouldShowUpdates(false);
        }}
      />
  
    </LeftSideWarper>
  );
};

interface NotificationProps {
  profileOpen: boolean;
}

const DivUserWrapper = styled.div`
  position: relative;
`;

export const Notification = styled.div<NotificationProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  border-radius: 50%;
  color: white;
  width: 1.3rem;
  height: 1.3rem;
  top: 1rem;
  left: 5rem;
  opacity: ${(props) => (props.profileOpen ? "0" : "1")};
  transition: opacity 0.3s ease-in-out;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: -0.2rem;
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    visibility: hidden;
  }
  to {
    transform: translateX(0%);
    visibility: visible;
  }
  `;

const slideInFromRight = keyframes`
  from {
    transform: translateX(0);
    visibility: visible;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
`;

const LeftSideWarper = styled.div`
  width: 22vw;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .slide-in-from-left {
    display: visible;
    animation: ${slideInFromLeft} 0.5s ease-in-out forwards;
  }
  .close {
    animation: ${slideInFromRight} 0.5s ease-in-out forwards;

  }
`;

export const LogoStyle = styled.img`
  width: 1.2rem;
  padding-right: 1rem;
`;
export const LogoStyleBell = styled.img`
  width: 3rem;
  margin-bottom: 1vh;

`;
