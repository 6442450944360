import {  useState } from "react";
import styled, { keyframes } from "styled-components";
import { BASE_URL } from "../../api/constants";
import bg from "../../assets/login/bg.png";
import { ContactButton } from "./ContactButton";
import { ContactInput } from "./ContactInput";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userInfo } from "../../store/userInfo";
import axios from "axios";
import { Dispatch } from "react";
import { ITicket } from ".";
import { RespondentNameTime } from "../RespondentNameTime";
import { unreadTiketsCount } from "../BurgerMenu";
interface IProps {
  setViewTicket: Dispatch<React.SetStateAction<boolean>>;
  ticket: ITicket;
  isSupportUser: boolean;
}
const replyToTicket = (
  ticketId: String,
  reply: String,
  respondentNameId: string,
  setLoading: Dispatch<React.SetStateAction<boolean>>,
  setContinueButton: Dispatch<React.SetStateAction<boolean>>
) => {
  if (!reply) {
    return false;
  }
  setLoading(true);
  return axios
    .post(
      `${BASE_URL}/contactTickets/reply`,
      {
        ticketId,
        reply,
        respondentNameId,
      },
      { withCredentials: true }
    )
    .then((res) => {
      if (res?.data?.success) {
        setContinueButton(false);
        setLoading(false);
        return true;
      }
      setContinueButton(true);
      setLoading(false);
      return false;
    });
};

export const ViewTicket = ({
  setViewTicket,
  isSupportUser,
  ticket,
}: IProps) => {
  const user = useRecoilValue(userInfo);
  const [continueButton, setContinueButton] = useState(true);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [reply, setReply] = useState(ticket?.reply ? ticket.reply : "");
  const [respondentNameId, setRespondentNameId] = useState(
    ticket?.respondentNameId ? ticket.respondentNameId : ""
  );
  const respondentDate = ticket?.respondentDate
    ? ticket.respondentDate
    : new Date();

  const email = user.user.userPrincipalName;
  const setUnreadTiketsCount = useSetRecoilState(unreadTiketsCount);

  return (
    <Wrapper>
      <br />
      <FormWrapper>
        <Header>
          {isSupportUser ? (
            ticket?.reply ? (
              <h4>{`טופל`}</h4>
            ) : (
              <h3>{`פנייה לטיפול`}</h3>
            )
          ) : (
            <h4>{`הפנייה טופלה`}</h4>
          )}
        </Header>
        <br />
        <InputsWrapper>
          <ContactInput
            placeholder=""
            value={ticket.fullName}
            isDisabled={true}
            setValue={setContent}
            isTextArea={false}
            topText={"שם"}
            bottomText={""}
          ></ContactInput>

          <ContactInput
            placeholder=""
            value={ticket.content}
            isDisabled={true}
            setValue={setContent}
            isTextArea={true}
            topText={"תוכן הפנייה"}
            bottomText={""}
          ></ContactInput>

          {isSupportUser ? (
            <ContactInput
              placeholder=""
              value={reply}
              setValue={setReply}
              isTextArea={true}
              topText={"תשובה"}
              bottomText={""}
            ></ContactInput>
          ) : (
            ticket.reply && (
              <ContactInput
                placeholder=""
                isDisabled={true}
                value={reply}
                setValue={setReply}
                isTextArea={true}
                topText={"תשובה"}
                bottomText={""}
              ></ContactInput>
            )
          )}
          {ticket.status == "closed" && respondentNameId && (
            <RespondentNameTime
              respondentNameId={respondentNameId}
              respondentDate={respondentDate}
            ></RespondentNameTime>
          )}
        </InputsWrapper>
        {loading ? (
          <DivInmationLoading className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </DivInmationLoading>
        ) : (
          isSupportUser && message && <Message>{message}</Message>
        )}
        {!loading && (
          <Buttons>
            {isSupportUser && continueButton && (
              <ContactButton
                onClick={async () => {
                  const sent = await replyToTicket(
                    ticket._id,
                    reply,
                    email,
                    setLoading,
                    setContinueButton
                  );
                    setUnreadTiketsCount((prev) => prev + 1);
                  if (sent) {
                    setMessage("הפנייה נשלחה בהצלחה.");
                  } else {
                    setMessage("קרתה תקלה בעת שליחת הפנייה");
                  }
                }}
              >
                {"שלח"}
              </ContactButton>
            )}
            <ContactButton
              onClick={() => setViewTicket(false)}
            >{`חזור`}</ContactButton>
          </Buttons>
        )}
      </FormWrapper>
    </Wrapper>
  );
};

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }

`;
const ellipsis3 = keyframes` 
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const ellipsis2 = keyframes` 
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const DivInmationLoading = styled.div`
  &&.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 2.3rem;
  }
  &&.lds-ellipsis div {
    position: absolute;
    top: 0rem;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #8a8989;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  &&.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: ${ellipsis1} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: ${ellipsis3} 0.6s infinite;
  }
`;

const Message = styled.div`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const InputsWrapper = styled.div`
  height: 60vh;
  width: inherit;
  margin: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: white;
  border-radius: 1rem;
  width: 90vw;
  margin-right: 5vw;
  max-height: 95vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    padding-top: 1rem;
  }
  p {
    align-self: center;
    text-align: center;
    /* font-size: 1.2rem; */
    font-weight: 600;
    width: 80%;
  }
`;
const Wrapper = styled.div`
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
`;
