import axios from "axios";
import React, { Dispatch, useState } from "react";
import { useRecoilValue } from "recoil";
import { BASE_URL } from "../../../api/constants";
import trashB from "../../../assets/Svgs/trashB.svg";
import trashY from "../../../assets/Svgs/trashY.svg";
import { userInfo } from "../../../store/userInfo";
import { TreeNodeType } from "../../TreeNode";

interface IProps {
  setText: Dispatch<React.SetStateAction<string>>;
  setfileName: Dispatch<React.SetStateAction<string>>;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
  shortName: string;
  node: TreeNodeType | undefined;
  refreshParent?: Function;
}

export const FileDelete = ({
  setText,
  node,
  refreshParent,
  setShowModal,
  setfileName,
}: IProps) => {
  const [trashLogo, setTrashLogo] = useState(trashB);
  const user = useRecoilValue(userInfo);

  return (
    <button
      onTouchStart={() => setTrashLogo(trashY)}
      onMouseDown={() => setTrashLogo(trashY)}
      onMouseUp={() => setTrashLogo(trashB)}
      onTouchEnd={() => setTrashLogo(trashB)}
      onClick={() => {
        setText("טוען..");
        setfileName("טוען.."); 
        axios.post(
          `${BASE_URL}/fileLogs/sendFileLog`,
          {  email: user.user.email,
             action: "Delete", fileName:node?.name},
          {
            withCredentials: true,
          }
        );
        return axios
          .post(
            `${BASE_URL}/tree/delete`,
            { node },
            {
              headers: {
                id: user.user.userPrincipalName,
              },
              withCredentials: true,
            }
          )
          .then((res) => {
            setfileName("הקובץ נמחק.");
            refreshParent && refreshParent(node?._id);
            setShowModal(false);
            return res.data;
          })
          .catch((err) => {
            console.log(err);
            setfileName("המערכת לא הצליחה למחוק את הקובץ.");
            return;
          });
      }}
    >
      <img className={"star"} src={trashLogo} alt={"מועדפים"} />
    </button>
  );
};
