import {
  PersonalArea,
  SectionWrapper,
  Img,
  TopWrapper,
  UserName,
  PersonalSectionWrapper,
  BigSectionWrapper,
  PersonalImg,
} from "../AdminTop/styles";
import PersonalAreaImg from "../../../assets/user_1 1 (1).png";
import messages from "../../../assets/Group 599.png";
import heart from "../../../assets/star.png";
import whatsapp from "../../../assets/XMLID_468_.png";
import phone from "../../../assets/Vector.png";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userInfo } from "../../../store/userInfo";
import { useHistory } from "react-router";
import { unreadTiketsCount,  shouldShowFavorites } from "..";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../api/constants";
import styled from "styled-components";

interface IProps {
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserTop = ({ setMenuOpen }: IProps) => {
  const userDetails = useRecoilValue(userInfo);
  const user = useRecoilValue(userInfo);
  const history = useHistory();
  const setShouldShowFavorites = useSetRecoilState(shouldShowFavorites);
  const userName = user.user.displayName;
  const email = userDetails.user.userPrincipalName;

  const [unreadCount,setUnreadCount] = useRecoilState(unreadTiketsCount);
useEffect(() => {
 
   axios
   .post(`${BASE_URL}/contactTickets/unreadTiketsCount`, 
      {email}, 
      {
        withCredentials: true,
      })
    .then((result) => {
        if (result.data.success && result.data.count !== undefined) {
          setUnreadCount(result.data.count);
        }
      })
    .catch((error) => {
        console.error('Error fetching unread count:', error);
      });
  }, [setUnreadCount, email]);

const resetUnreadNotifications = async () => {
  try {
    const result = await axios.post(`${BASE_URL}/contactTickets/resetUnreadTiketsCount`, 
    {
      email
    }, 
    {
      withCredentials: true,
    });

    if (result.data.success) {
      setUnreadCount(result.data.count);
    }
 } catch (error) {
    console.error('Error resetting unread notifications count:', error);
  }
};
  return (
    <>
      <TopWrapper>
        {unreadCount > 0 && (
          <Notification profileOpen={false}>
            <div>{unreadCount}</div>
          </Notification>
        )}
        <UserName>
          <p>{` שלום, ${userName} `}</p>
        </UserName>
      </TopWrapper>
      <PersonalSectionWrapper>
        <PersonalArea>
          <PersonalImg src={PersonalAreaImg} alt=""></PersonalImg>
          <p>איזור האישי</p>
        </PersonalArea>
      </PersonalSectionWrapper>

      <BigSectionWrapper>
        <SectionWrapper
          onClick={() => {
            setShouldShowFavorites(true);
            setMenuOpen(false);
            history.push("/");
          }}
        >
          <Img src={heart} alt=""></Img>
          <p>מועדפים</p>
        </SectionWrapper>
      </BigSectionWrapper>

      <BigSectionWrapper>
        <SectionWrapper
          onClick={() => {
            setMenuOpen(false);
            // setShouldShowContact(true);
            history.push("/contact");
            resetUnreadNotifications(); 
            setUnreadCount(0);
          }}
        >
          <Img src={messages} alt=""></Img>
          <p>פנו אלינו</p>
        </SectionWrapper>
      </BigSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper href={`tel:972529410491`}>
          <Img src={phone} alt=""></Img>
          <p>דברו איתנו</p>
        </SectionWrapper>
      </BigSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper
          href={`https://wa.me/972529410491?text`}
          data-action="share/whatsapp/share"
          target="_blank"
        >
          <Img src={whatsapp} alt=""></Img>
          <p>כתבו לנו</p>
        </SectionWrapper>
      </BigSectionWrapper>
    </>
  );
};
interface NotificationProps {
  profileOpen: boolean;
}
 const Notification = styled.div<NotificationProps>`
  position: absolute;
  /* z-index: 999; */
  /* overflow: visible; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  border-radius: 50%;
  color: white;
  width: 1.3rem;
  height: 1.3rem;
  top: 15rem;
  right:1.3rem ;
  /* margin-left: 2.1rem;
  margin-top: -0.8rem; */
  opacity: ${(props) => (props.profileOpen ? "0" : "1")};
  transition: opacity 0.3s ease-in-out;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: -0.2rem;
  }
`;
