import {
  PersonalArea,
  SectionWrapper,
  Img,
  TopWrapper,
  UserName,
  PersonalSectionWrapper,
  BigSectionWrapper,
  AlertsImg,
} from "./styles";
import PersonalAreaImg from "../../../assets/Svgs/user_1 1.svg";
import messages from "../../../assets/Svgs/Isolation_Mode (2).svg";
import heart from "../../../assets/Svgs/Object.svg";
import dashboard from "../../../assets/Svgs/dashboard.svg";
import alerts from "../../../assets/Svgs/ball 1.svg";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userInfo } from "../../../store/userInfo";
import { useHistory } from "react-router";
import { shouldShowFavorites } from "..";
import styled from "styled-components";
interface IProps {
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  numberNotification: number;
}

export const AdminTop = ({ setMenuOpen, numberNotification }: IProps) => {
  const user = useRecoilValue(userInfo);
  const history = useHistory();
  const setShouldShowFavorites = useSetRecoilState(shouldShowFavorites);

  const userName = user.user.displayName;

  return (
    <>
      <TopWrapper>
        {numberNotification > 0 && (
          <Notification profileOpen={false}>
            <div>{numberNotification}</div>
          </Notification>
        )}
        <UserName>
          <p>{` שלום, ${userName} `}</p>
        </UserName>
      </TopWrapper>
      <PersonalSectionWrapper>
        <PersonalArea>
          <Img src={PersonalAreaImg} alt=""></Img>
          <p>איזור האישי</p>
        </PersonalArea>
      </PersonalSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper
          onClick={() => {
            setShouldShowFavorites(true);
            setMenuOpen(false);
            history.push("/");
          }}
        >
          <Img src={heart} alt=""></Img>
          <p>מועדפים</p>
        </SectionWrapper>
      </BigSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper
          onClick={() => {
            history.push("/contact");
          }}
        >
          <Img src={messages} alt=""></Img>
          <p>פניות</p>
        </SectionWrapper>
      </BigSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper
          onClick={() => {
            history.push("/adminUpdates");
          }}
        >
          <AlertsImg src={alerts} alt=""></AlertsImg>
          <p>יצירת התרעות</p>
        </SectionWrapper>
      </BigSectionWrapper>
      <BigSectionWrapper>
        <SectionWrapper
          onClick={() => {
            history.push("/dashboard");
          }}
        >
          <Img src={dashboard} alt=""></Img>
          <p>דשבורד</p>
        </SectionWrapper>
      </BigSectionWrapper>
    </>
  );
};
interface NotificationProps {
  profileOpen: boolean;
}
 const Notification = styled.div<NotificationProps>`
  position: absolute;
  /* z-index: 999; */
  /* overflow: visible; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  border-radius: 50%;
  color: white;
  width: 1.3rem;
  height: 1.3rem;
  top: 15rem;
  right: 1rem;
  /* margin-left: 2.1rem;
  margin-top: -0.8rem; */
  opacity: ${(props) => (props.profileOpen ? "0" : "1")};
  transition: opacity 0.3s ease-in-out;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: -0.2rem;
  }
`;
