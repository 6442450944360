import React, { useEffect, useState } from "react";
import { DefaultContainer } from "../../containers/DefaultContainer";
import Whatsapp from "../../assets/Svgs/icons8-whatsapp.svg";
import { SelectChangeEvent } from "@mui/material/Select";

import {
  FieldName,
  SafetyForm,
  FieldWrapper,
  Title,
  SendButton,
  SendButtonWrapper,
  EventSeverity,
  Severity,

  ErrorMes,
  RigthMes,
  PicDiv,
  P,

  FieldName2,

  Span,

  TitleWrapper,
  Instruction,

  P1,
  BottomDiv,
} from "./styles";

import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import { SafetyModal } from "./SafetyModal";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import { Field } from "./SelectField";
import { TextAreaField } from "./TextAreaField";
import { ImageUpload } from "./ImageUpload";
import { Map } from "./map";
import { LocationNtz } from "./Location";
import { Injury, InjuryOption } from "./Injury";
import { DateAndTimeField } from "./DateAndTime";
import { SpecialEvent } from "./SpecialEvent";

export const SafetyReports = () => {
  const user = useRecoilValue(userInfo);
  const userName = user.user.displayName;
  const email = user.user.userPrincipalName;
  const [locationAdress, setLocationAdress] = useState("");
  const [filenames, setFilenames] = useState<string[]>();
  const [errorMessage, setErrorMessage] = useState("");
  const [isInjuries, setIsInjuries] = useState(true);
  const [isDamage, setIsDamage] = useState(true);
  const [rigthMes, setRigthMes] = useState("");
  const [locationMes, setLocationMes] = useState("");
  const [imageMessage, setImageMessage] = useState("");
  const [unitName, setUnitName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedActivityType, setActivityType] = useState("");
  const [selectedUnitActivity, setUnitActivity] = useState("");
  const [ntz, setNtz] = useState("");
  const [selectedResults, setResults] = useState("");
  const [selectedInjuriesLevel, setInjuriesLevel] = useState("");
  const [selectedFinancialDamage, setFinancialDamage] = useState("בחר/י");
  const [selectedEventSeverity, setEventSeverity] = useState("");
  const [eventSeverityBackgroundColor, setEventSeverityBackgroundColor] =
    useState("");
  const [selectedLocation, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [recommendations, setRecommendations] = useState("");

  const [longitude, setLongitude] = useState<number>(32.058731);
  const [latitude, setLatitude] = useState<number>(34.849529);
  const [specialNum, setSpecialNum] = useState<number>(1);
  const [sumOfAllInjuries, setSumOfAllInjuries] = useState<number>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [toolTipMessage, setToolTipMessage] = useState("");
  const [shouldShow, setShouldShow] = useState(false);
  const [specialEvent, setSpecialEvent] = React.useState<string[]>([]);
  const [specialEventsArr, setSpecialEventsArr] = React.useState<
    { name: string; value: number }[]
  >([{ name: "", value: 1 }]);
  const [specialArrayName, setSpecialArrayName] = useState<string>("");
  const [isSpecialEvent, setIsSpecialEvent] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isLocationGood, setIsLocationGood] = useState(false);
  const [canAddImg, setCanAddImg] = useState(false);
  const [canAddMap, setCanAddMap] = useState(false);

  const [injuriesList, setInjuriesList] = useState<InjuryOption[]>([]);
  const [realSpecial, setRealSpecial] = useState("");
  const [whatsappMes, setWhatsappMes] = useState("");
  const now = new Date();
  var today, dd, mm, yyyy;
  today = new Date();
  dd = today.getDate();
  mm = today.getMonth() + 1;
  yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  const currentDate = yyyy + "-" + mm + "-" + dd;

  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [reverseDate, setReverseDate] = useState(currentDate);
  const ResetFields = () => {
    setDescription("");
    setUnitName("");
    setDate("");
    setTime("");
    setSelectedCategory("");
    setActivityType("");
    setUnitActivity("");
    setResults("");
    // setResultsDetails("");
    setFinancialDamage("");
    setLocation("");
    setRecommendations("");
    setPhoneNumber("");
    setInjuriesList([]);
    setEventSeverity("");
    setImageMessage("");
    setLocationMes("");
    setFilenames(undefined);
    setSumOfAllInjuries(0);
    setImagesUrl("");
    setNtz("");
    setLatitude(34.849529);
    setLongitude(32.058731);
    setLocationAdress("");
    setWhatsappMes("");
    setTimeout(() => {
      setRigthMes("");
    }, 5000);
  };

  let S = "";
  useEffect(() => {
    const newArr = injuriesList;
    newArr.map(({ injuryLevel, numOfInjuries }) => {
      S += `${numOfInjuries} ${injuryLevel}, `;
    });
    S = S.slice(0, -2);
    setInjuriesLevel(S);
  }, [injuriesList]);

  let R = "";
  useEffect(() => {
    specialEvent.map((specialEvent) => {
      R += `${specialEvent}, `;
    });
    R = R.slice(0, -2);
    setRealSpecial(R);
  }, [specialEvent]);

  useEffect(() => {
    if (
      selectedLocation === "בסיס" ||  selectedLocation === "שטח אש" ||  selectedLocation === "רציף" ||
      selectedActivityType === "פעילות מבצעית/לחימה" ||
      (selectedLocation === "" && selectedActivityType === "")
    ) {
      setIsLocationGood(false);
      setNtz("");
      setLatitude(34.849529);
      setLongitude(32.058731);
      setLocationAdress("");
      setLocationMes("");
    } else setIsLocationGood(true);
    if (selectedLocation === "שטח אזרחי") {
      setCanAddImg(true);
      setCanAddMap(true);
    } else{
      setCanAddImg(false);
      setCanAddMap(false);
      setImagesUrl("");
      setNtz("");
      setLatitude(34.849529);
      setLongitude(32.058731);
      setLocationAdress("");
      setLocationMes("");
    } 
  }, [selectedLocation, selectedActivityType]);
  // useEffect(() => {
  //   if (selectedLocation !== "שטח אזרחי") {
  //     setNtz("");
  //     setLocationAdress("");
  //     setLatitude(34.849529);
  //     setLongitude(32.058731);
  //     setLocationMes("");  
  //   }
  // }, [selectedLocation]);
  // console.log(locationMes)

  useEffect(() => {
    switch (selectedCategory) {
      case "נשק ומקלעים":
        setSpecialEventsArr(special.gunArr);
        setSpecialArrayName("gunArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case `אמל"ח (לא נשק/מקלעים)`:
        setSpecialEventsArr(special.WeaponsArr);
        setSpecialArrayName("WeaponsArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case "תחמושת":
        setSpecialEventsArr(special.munitionsArr);
        setSpecialArrayName("munitionsArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case "חריגות ירי או תנועה של כוחות בשטחי אימונים":
        setSpecialEventsArr(special.specialShotArr);
        setSpecialArrayName("specialShotArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case "מזג-אוויר":
        setSpecialEventsArr(special.weatherArr);
        setSpecialArrayName("weatherArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case `ירי דו"צ`:
        setSpecialEventsArr(special.crossFireArr);
        setSpecialArrayName("crossFireArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case "דרכים":
        setSpecialEventsArr(special.roadsArr);
        setSpecialArrayName("roadsArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case "אש":
        setSpecialEventsArr(special.fireArr);
        setSpecialArrayName("fireArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case `חומ"ס`:
        setSpecialEventsArr(special.dangerousMaterialsArr);
        setSpecialArrayName("dangerousMaterialsArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case `רק"מ וצמ"ה קרביים`:
        setSpecialEventsArr(special.bigCarsArr);
        setSpecialArrayName("bigCarsArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      case "עבודה":
        setSpecialEventsArr(special.workArr);
        setSpecialArrayName("workArr");
        setSpecialEvent([]);
        setIsSpecialEvent(true);
        break;
      default:
        setSpecialEventsArr([{ name: "", value: 1 }]);
        setSpecialEvent([]);
        // setSpecialEvent([""]);
        setIsSpecialEvent(false);
    }
  }, [selectedCategory]);

  useEffect(() => {
    setSpecialNum(1);
    const key = specialArrayName as keyof typeof special;
    const arr = specialEvent.map((s) =>
      special[key].find(
        (src: { name: string; value: number }) => src.name === s
      )
    );

    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]?.value === 2 && specialNum !== 3) {
          setSpecialNum(2);
        } else if (arr[i]?.value === 3) {
          setSpecialNum(3);
        }
      }
    } else setSpecialNum(1);
  }, [specialArrayName, specialEvent, specialNum]);

  useEffect(() => {
    if (
      injuriesList.length === 0 &&
      selectedFinancialDamage === "בחר/י" &&
      specialEvent
    ) {
      setEventSeverity("");
    }
    if (selectedResults === `א.נ.א.נ (אין נפגעים, אין נזק) `) {
      setEventSeverity("קל");
    }
    if (injuriesList.length > 0) {
      for (let i = 0; i < injuriesList.length; i++) {
        if (
          injuriesList[i].injuryLevel?.includes("ללא פגיעה") ||
          injuriesList[i].injuryLevel?.includes("פצוע קל (ללא אשפוז)") ||
          injuriesList[i].injuryLevel?.includes("פצוע קל (שאושפז)") ||
          selectedFinancialDamage === `אין נזק` ||
          selectedFinancialDamage === `נזק קל (נמוך מ-10,000 ש"ח)` ||
          specialNum === 1
        ) {
          setEventSeverity("קל");
        }
        if (
          injuriesList[i].injuryLevel?.includes("פצוע בינוני") ||
          selectedFinancialDamage === `נזק בינוני (בין 10,000-300,000 ש"ח)` ||
          specialNum === 2
        ) {
          setEventSeverity("בינוני");
        }
        if (
          injuriesList[i].injuryLevel?.includes("פצוע קשה/אנוש") ||
          injuriesList[i].injuryLevel?.includes("חלל") ||
          selectedFinancialDamage === `נזק גבוה (מעל 300,000 ש"ח)` ||
          specialNum === 3
        ) {
          setEventSeverity("חמור");
        }
      }
    } else {
      if (
        selectedFinancialDamage === `אין נזק` ||
        selectedFinancialDamage === `נזק קל (נמוך מ-10,000 ש"ח)` ||
        specialNum === 1
      ) {
        setEventSeverity("קל");
      }
      if (
        selectedFinancialDamage === `נזק בינוני (בין 10,000-300,000 ש"ח)` ||
        specialNum === 2
      ) {
        setEventSeverity("בינוני");
      }
      if (
        selectedFinancialDamage === `נזק גבוה (מעל 300,000 ש"ח)` ||
        specialNum === 3
      ) {
        setEventSeverity("חמור");
      }
    }
  }, [
    injuriesList,
    selectedFinancialDamage,
    selectedResults,
    specialEvent,
    specialNum,
  ]);

  useEffect(() => {
    if (selectedEventSeverity === "") {
      setEventSeverityBackgroundColor("#e4e4e4");
    }
    if (selectedEventSeverity === "קל") {
      setEventSeverityBackgroundColor("#3af077");
    }
    if (selectedEventSeverity === "בינוני") {
      setEventSeverityBackgroundColor("#ffa82e");
    }
    if (selectedEventSeverity === "חמור") {
      setEventSeverityBackgroundColor("#f95f66");
    }
  }, [selectedEventSeverity]);

  useEffect(() => {
    if (selectedResults === `א.נ.א.נ (אין נפגעים, אין נזק) `) {
      setIsInjuries(false);
      setIsDamage(false);
      setInjuriesList([]);
      setSumOfAllInjuries(0);
      setFinancialDamage("");
    } else if (selectedResults === `א.נ.י.נ (אין נפגעים, יש נזק) `) {
      setIsInjuries(false);
      setIsDamage(true);
      setInjuriesList([]);
      setSumOfAllInjuries(0);
      setFinancialDamage("בחר/י");
    } else if (selectedResults === `י.נ.א.נ (יש נפגעים, אין נזק) `) {
      setIsDamage(false);
      setIsInjuries(true);
      setFinancialDamage("");
    } else if (selectedResults === `י.נ.י.נ (יש נפגעים, יש נזק) `) {
      setIsDamage(true);
      setIsInjuries(true);
      setFinancialDamage("בחר/י");
    }
  }, [selectedResults]);
  useEffect(() => {
    if (filenames) {
      setImageMessage(`${filenames?.length} קבצים צורפו בהצלחה`);
    }
  }, [filenames]);

  useEffect(() => {
    const newDate = new Date(date);
    if (newDate.getDate() > 9 && newDate.getMonth() > 9) {
      setReverseDate(
        `${newDate.getDate()}-${
          newDate.getMonth() + 1
        }-${newDate.getFullYear()}`
      );
    }
    if (newDate.getDate() > 9 && newDate.getMonth() < 10) {
      setReverseDate(
        `${newDate.getDate()}-0${
          newDate.getMonth() + 1
        }-${newDate.getFullYear()}`
      );
    }
    if (newDate.getDate() < 9 && newDate.getMonth() > 9) {
      setReverseDate(
        `0${newDate.getDate()}-${
          newDate.getMonth() + 1
        }-${newDate.getFullYear()}`
      );
    }
    if (newDate.getDate() < 9 && newDate.getMonth() < 9) {
      setReverseDate(
        `0${newDate.getDate()}-0${
          newDate.getMonth() + 1
        }-${newDate.getFullYear()}`
      );
    }
  }, [date]);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleSafetyReportImageUpload = async (files: any, userId: string) => {
    const formData = new FormData();

    Array.from(files).map((file: any) => {
      formData.append("files", file);
    });

    const { data } = await axios.post(
      `${BASE_URL}/safetyreports/upload_image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          id: userId,
        },
        withCredentials: true,
      }
    );
    return data;
  };

  const handleUploadClick = () => {
    hiddenFileInput.current!.click();
    setIsLoading(true);
    setImageMessage("טוען העלאת תמונה...");
  };

  const handleChangeFiles = async (e: any) => {
    const data = await handleSafetyReportImageUpload(
      e.target.files,
      user.user.userPrincipalName
    );
    if (data.success) {
      setFilenames((prev) => {
        if (!prev) {
          return [...data.filenames];
        }
        let prevCopy: string[] = prev;
        data.filenames?.map((filename: string) => {
          prevCopy = [...prevCopy, filename];
        });
        return prevCopy;
      });
      setIsLoading(false);
    }
  };

  const CheckIfEqual = () => {
    let count = 0;
    injuriesList.map((num, i) => {
      count += injuriesList[i].numOfInjuries;
    });
    if (count === sumOfAllInjuries) {
      return true;
    } else {
      return false;
    }
  };
  const validateFields = () => {
    if (isInjuries && !CheckIfEqual()) {
      setErrorMessage("מספר הפצועים לא תואם");
    } else if (description === "") {
      setErrorMessage("יש להכניס את תיאור האירוע");
    } else if (date === "") {
      setErrorMessage("יש להכניס תאריך");
    } else if (time === "") {
      setErrorMessage("יש להכניס שעה");
    } else if (!phoneNumber) {
      setErrorMessage("יש להכניס מספר טלפון");
    } else if (!selectedCategory || selectedCategory === "בחר/י") {
      setErrorMessage("יש לבחור מאפיין תחומי");
    } else if (!selectedActivityType || selectedActivityType === "בחר/י") {
      setErrorMessage("יש לבחור מאפיין פעילות הפרט");
    } else if (!selectedUnitActivity || selectedUnitActivity === "בחר/י") {
      setErrorMessage("יש לבחור מאפיין פעילות היחידה");
    } else if (!selectedResults || selectedResults === "בחר/י") {
      setErrorMessage("יש לבחור את תוצאות האירוע");
    } else if (isInjuries && injuriesList.length === 0) {
      setErrorMessage("יש לבחור את חומרת הפגיעה");
    } else if (
      isDamage &&
      (!selectedFinancialDamage || selectedFinancialDamage === "בחר/י")
    ) {
      setErrorMessage("יש לבחור את חומרת הנזק");
    } else if (!selectedLocation || selectedLocation === "בחר/י") {
      setErrorMessage("יש לבחור מיקום");
    } else {
      setErrorMessage("");
      return true;
    }
    return false;
  };

  const handleSelectChange = (
    event: SelectChangeEvent<typeof specialEvent>
  ) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setSpecialEvent(typeof value === "string" ? value.split(",") : value);
  };

  const objHebrew: ObjectType = {
    unitName: "*שם יחידה*",
    results: "*תוצאות האירוע*",
    sumOfAllInjuries: `*סה"כ כמות פצועים*`,
    injuries_level: "*חומרת הפגיעה באדם*",
    financial_damage: "*נזק לרכוש*",
    reverseDate: "*תאריך*",
    time: "*שעה*",
    location: "*מקום האירוע*",
    loctionAddress: "*פירוט מיקום*",
    liveLocation: "*נ.צ*",
    category: "*מאפיין תחומי*",
    specialEvent: "*מקרים חריגים*",
    activity_type: "*מאפיין פעילות הפרט*",
    unit_activity_type: "*מאפיין פעילות היחידה*",
    description: "*תיאור האירוע*",
    recommendations: "*המלצות ראשוניות*",
    userName: "*שם המדווח*",
    phoneNumber: "*מספר טלפון*",
    image: "*תמונות מהאירוע*",
  };

  interface ObjectType {
    [key: string]: string | string[] | undefined;
  }

  const ChangeToHebrew = (key: string) => {
    if (key in objHebrew) {
      return objHebrew[key];
    }
  };
  const obj: ObjectType = {
    userName: userName,
    phoneNumber: phoneNumber,
    results: selectedResults,
    sumOfAllInjuries: String(sumOfAllInjuries),
    injuries_level: selectedInjuriesLevel,
    financial_damage: selectedFinancialDamage,
    reverseDate,
    time,
    location: selectedLocation,
    loctionAddress: locationAdress,
    category: selectedCategory,
    specialEvent: realSpecial,
    activity_type: selectedActivityType,
    unit_activity_type: selectedUnitActivity,
    description,
    recommendations: recommendations,
    images: filenames,
  };

  let [imagesUrl, setImagesUrl] = useState("");
  let [oneImage, setOneImage] = useState("");

  let photoNumber = 1;

  const getImages = async () =>
    await Object.keys(obj).map(async (key: string, i) => {
      if (key === "images") {
        if (typeof obj.images !== "string" && obj.images) {
          await Promise.all(
            obj.images.map(async (image, index) => {
              await axios
                .get(`${BASE_URL}/tree/downloadFile/${image}`, {
                  withCredentials: true,
                })
                .then(({ data }) => {
                  oneImage += `*תמונה ${photoNumber}*: ${data} \n`;
                  photoNumber++;
                });
            })
          );
        }
      }
      setImagesUrl(oneImage);
      setOneImage("");
    });

  useEffect(() => {
    getImages();
  }, [filenames]);

  let str = "";

  if (unitName !== "") {
    str += `*דיווח ראשוני לאירוע ${selectedCategory} ${selectedEventSeverity}, ${unitName}*\n`;
  } else {
    str += `*דיווח ראשוני לאירוע ${selectedCategory} ${selectedEventSeverity}*\n`;
  }

  const handleSubmit = async () => {
    if (validateFields()) {
      axios.post(`${BASE_URL}/safetyreports/createreport`, {
        email,
        userName,
        unitName,
        phoneNumber,
        date: reverseDate,
        time,
        description,
        images: imagesUrl,
        category: selectedCategory,
        specialEvent: realSpecial,
        activity_type: selectedActivityType,
        unit_activity_type: selectedUnitActivity,
        results: selectedResults,
        sumOfAllInjuries: sumOfAllInjuries,
        injuries_level: injuriesList,
        financial_damage: selectedFinancialDamage,
        event_severity: selectedEventSeverity,
        location: selectedLocation,
        locationAdress: locationAdress,
        recommendations: recommendations,
        gps_location: {
          latitude,
          longitude,
        },
      });
      await Promise.all(
        Object.keys(obj).map(async (key: string, i) => {
          if (obj[key] !== "" && obj[key] !== "0" && key !== "images")
            str += `${ChangeToHebrew(key)}: ${obj[key]} \n`;
          // }
          return;
        })
      ).then(() => {
        str += imagesUrl;
        if (latitude !== 34.849529) {
          str += `*מיקום האירוע במפה*:\n https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`;
        }
        navigator.clipboard.writeText(str);
        console.log(str);
        setWhatsappMes(str);
        setToolTipMessage("הדיווח נשמר בהצלחה");
        setShouldShow(true);
        setRigthMes("");
        // handlePdf();
        ResetFields();
      });
    } else {
      console.log("false");
    }
  };

  return (
    <DefaultContainer search={true} background="">
      <TitleWrapper>
        <Title>דיווח אירועי בטיחות</Title>
        <Instruction
          href="https://safetydevstorage.blob.core.windows.net/files/%D7%94%D7%95%D7%A8%D7%90%D7%94%200.7%20-%20%D7%A4%D7%A2%D7%95%D7%9C%D7%95%D7%AA%20%D7%9E%D7%97%D7%99%D7%99%D7%91%D7%95%D7%AA%20%D7%91%D7%A7%D7%A8%D7%95%D7%AA%20%D7%90%D7%99%D7%A8%D7%95%D7%A2%20%D7%91%D7%98%D7%99%D7%97%D7%95%D7%AA.pdf?sp=r&st=2021-09-13T23:17:43Z&se=2031-09-14T09:17:43Z&spr=https&sv=2020-08-04&sr=c&sig=lk2kZQXFFHC3lf90jDS5AOqmU5ZPDsd5ile2Anx0Qp0%3D"
          target="_blank"
        >
          מעבר להוראה 0.7
        </Instruction>
      </TitleWrapper>
      <FieldName2>
        שים לב! עליך לדווח אירוע בטיחות עבור יחידתך בלבד, וזאת בלבד והינך בעל
        תפקיד מורשה לדיווח אירוע בטיחות.
      </FieldName2>
      <SafetyForm>
        <SafetyModal shouldShow={shouldShow} setShouldShow={setShouldShow}>
          {toolTipMessage}
        </SafetyModal>
        <Field
          value={unitName}
          fieldLabel="שם היחידה"
          placeHolder={"אין לציין שם יחידה רגישה"}
          maxLength={15}
          onChange={(e) => {
            setUnitName(e.target.value);
          }}
        />
        <Field
          value={selectedResults}
          fieldLabel="תוצאות האירוע"
          onChange={(e) => {
            setResults(e.target.value);
          }}
          data={resultsArr}
          isRequired
        />
        {isInjuries ? (
          <FieldWrapper>
            <Field
              type="number"
              value={sumOfAllInjuries || ""}
              fieldLabel={`סה"כ כמות פצועים`}
              placeHolder={"כמות פצועים באירוע"}
              onChange={(e) => {
                setSumOfAllInjuries(Number(e.target.value));
              }}
              isRequired
            />
            <FieldName>
              חומרת הפגיעה באדם<Span>*</Span>
            </FieldName>
            {/* <InjuriesWrapper> */}
            {injuriesList.map((injury, index) => {
              return (
                <Injury
                  key={`${injury.injuryLevel} + ${injury.numOfInjuries}`}
                  injuryLevelInput={injury.injuryLevel}
                  numOfInjuriesInput={injury.numOfInjuries}
                  placeholder={undefined}
                  setInjuries={setInjuriesList}
                  isDisabled={true}
                />
              );
            })}
            <Injury
              injuryLevelInput={undefined}
              numOfInjuriesInput="הכנס כמות פצועים"
              placeholder="כמות פצועים לפי חומרה"
              setInjuries={setInjuriesList}
              isDisabled={false}
            />
          </FieldWrapper>
        ) : (
          ""
        )}
        {isDamage ? (
          <Field
            value={selectedFinancialDamage}
            fieldLabel="נזק לרכוש"
            onChange={(e) => {
              setFinancialDamage(e.target.value);
            }}
            data={financialDamageArr}
            isRequired
          />
        ) : (
          ""
        )}
        <DateAndTimeField
          DateValue={date}
          DateOnChange={(e) => setDate(e.target.value)}
          TimeValue={time}
          TimeOnChange={(e) => setTime(e.target.value)}
        />

        <Field
          value={selectedCategory}
          fieldLabel="מאפיין תחומי"
          onChange={(e) => {
            setSelectedCategory(e.target.value);
          }}
          data={categoryArr}
          isRequired
        />
        {isSpecialEvent ? (
          <SpecialEvent
            onChange={handleSelectChange}
            value={specialEvent}
            specialEventsArr={specialEventsArr}
          />
        ) : (
          ""
        )}
        <Field
          value={selectedActivityType}
          fieldLabel="מאפיין פעילות הפרט"
          onChange={(e) => {
            setActivityType(e.target.value);
          }}
          data={activityTypeArr}
          isRequired
        />
        <Field
          value={selectedUnitActivity}
          fieldLabel="מאפיין פעילות היחידה"
          onChange={(e) => {
            setUnitActivity(e.target.value);
          }}
          data={unitActivityTypeArr}
          isRequired
        />
        <Field
          value={selectedLocation}
          fieldLabel="מקום האירוע"
          onChange={(e) => {
            setLocation(e.target.value);
          }}
          data={locationArr}
          isRequired
        />
        {canAddMap && isLocationGood && (
          <Field
            value={ntz}
            fieldLabel="נ.צ האירוע"
            onChange={(e) => {
              setNtz(e.target.value);
            }}
            data={ntzArr}
          />
        // ) : (
        //   canAddMap && 
        //   <Field
        //   value={0}
        //   fieldLabel="נ.צ האירוע"
        //   onChange={(e) => {
        //     setNtz(e.target.value);
        //   }}
        //   data={ntzArr}
        // />
         )
        }
        {canAddMap && ntz === "שמירת נ.צ לפי מפה" && (
          <Map
            locationAdress={locationAdress}
            latitude={latitude}
            longitude={longitude}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setLocationAdress={setLocationAdress}
          />
        )}
        {canAddMap && ntz === "שמירת נ.צ נוכחי" ? (
          <LocationNtz
            locationMes={locationMes}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setLocationMes={setLocationMes}
          />
        ) : (
          ""
        )}
        <TextAreaField
          fieldLabel="תיאור האירוע"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          placeHolder="תאר את האירוע כאן..."
          isRequired
        />
        <TextAreaField
          fieldLabel="המלצות ראשוניות"
          value={recommendations}
          onChange={(e) => {
            setRecommendations(e.target.value);
          }}
          placeHolder="יש לכתוב את ההמלצות כאן... (רשות)"
        />
        <FieldWrapper>
          <Field
            value={phoneNumber}
            fieldLabel="טלפון מזין האירוע"
            placeHolder={"הכנס מספר טלפון"}
            maxLength={10}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[0-9]*$/.test(inputValue)) {
                setPhoneNumber(inputValue);
              }
            }}
            isRequired
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldName>חומרת אירוע מחושבת</FieldName>
          <EventSeverity color={eventSeverityBackgroundColor}>
            <Severity>{selectedEventSeverity}</Severity>
          </EventSeverity>
        </FieldWrapper>
        {canAddImg && (
          <ImageUpload
            onClickFunc={handleUploadClick}
            imageMessage={imageMessage}
            loading={loading}
          >
            <input
              type="file"
              name="file"
              ref={hiddenFileInput}
              multiple
              onChange={handleChangeFiles}
              style={{ display: "none" }}
            />
          </ImageUpload>
        )}

        <SendButtonWrapper>
          <SendButton onClick={handleSubmit}>שמור ושתף</SendButton>
          {errorMessage && <ErrorMes>{errorMessage}</ErrorMes>}
          {!errorMessage && <RigthMes>{rigthMes}</RigthMes>}
          <P>
            {"*לשיתוף הדיווח למוקד הבטיחות לחץ "}{" "}
            <P1>
              <span style={{ margin: "5px" }}>
                {" "}
                <a
                  href={`https://wa.me/972529410491?text=${whatsappMes}`}
                  data-action="share/whatsapp/share"
                  target="_blank"
                >
                  <img src={Whatsapp} width={30} />
                </a>
              </span>
            </P1>{" "}
            ובצע הדבק
          </P>

          {/* <Pdf onClick={handlePdf} width="50" height="50" /> */}
        </SendButtonWrapper>
      </SafetyForm>
      <BottomDiv />
    </DefaultContainer>
  );
};

const categoryArr = [
  "בחר/י",
  "נשק ומקלעים",
  "דרכים",
  "תחמושת",
  `ירי דו"צ`,
  "מזג-אוויר",
  `רק"מ וצמ"ה קרביים`,
  `שת"פ אוויר`,
  "עבודה",
  "אוויר",
  "בטיחות ימי",
  "ספורט ואקסטרים",
  "נפילות/חבלות",
  "חריגות ירי או תנועה של כוחות בשטחי אימונים",
  `חומ"ס`,
  `אמל"ח (לא נשק/מקלעים)`,
  "אש",
  `טג"ח קרבי`,
  `שת"פ ים`,
  "ייעודי עורף/חילוץ והצלה",
  "אמצעי רום קרוב לקרקע",
  "כושר גופני/קרבי",
];
const activityTypeArr = [
  "בחר/י",
  "פעילות מבצעית/לחימה",
  "אימון",
  "הכשרה",
  "שגרה",
  "פנאי",
  "חופשה",
];
const unitActivityTypeArr = [
  "בחר/י",
  'תע"ם',
  "אימונים",
  "הכשרה",
  "רגיעה / מנהלה",
  "פעילות מבצעית/תמרון (שטח אויב)",
  "פעילות מבצעית/לחימה (שטח ישראל)",
];
const ntzArr = ["בחר/י", "שמירת נ.צ נוכחי", "שמירת נ.צ לפי מפה"];
const resultsArr = [
  "בחר/י",
  `א.נ.א.נ (אין נפגעים, אין נזק) `,
  `א.נ.י.נ (אין נפגעים, יש נזק) `,
  `י.נ.א.נ (יש נפגעים, אין נזק) `,
  `י.נ.י.נ (יש נפגעים, יש נזק) `,
];
export const injuriesLevelArr = [
  "בחר/י",
  "ללא פגיעה",
  "פצוע קל (ללא אשפוז)",
  "פצוע קל (שאושפז)",
  "פצוע בינוני",
  "פצוע קשה/אנוש",
  "חלל",
];
const financialDamageArr = [
  "בחר/י",
  `נזק קל (נמוך מ-10,000 ש"ח)`,
  `נזק בינוני (בין 10,000-300,000 ש"ח)`,
  `נזק גבוה (מעל 300,000 ש"ח)`,
];
// const eventSeverityArr = ["בחר/י", "קל", "בינוני", "חמור"];
const locationArr = ["בחר/י", "בסיס", "שטח אזרחי", "שטח אש", "רציף"];

const special = {
  gunArr: [
    { name: "שימוש לא מקצועי בנשק", value: 1 },
    { name: "שימוש אסור בנשק", value: 2 },
    { name: "עבריינות בנשק", value: 3 },
  ],
  WeaponsArr: [
    { name: "פלטה", value: 2 },
    { name: `פיצוץ אמל"ח`, value: 2 },
  ],
  munitionsArr: [
    { name: "פלטה", value: 2 },
    { name: `פיצוץ תחמושת`, value: 2 },
    { name: `התפוצצות בלתי מבוקרת של תחמושת נפיצה`, value: 3 },
  ],
  specialShotArr: [
    { name: "טעות בירי תלול מסלול", value: 2 },
    { name: "ירי חי במהלך תרגיל יבש", value: 3 },
  ],
  weatherArr: [
    {
      name: `מקבץ של 2 או יותר מקרי היפותרמיה שנמדדו ונקבעו ע"י גורמי רפואה`,
      value: 2,
    },
    { name: `מכת חום שנמדדה ונקבעה ע"י גורמי רפואה`, value: 3 },
  ],
  crossFireArr: [
    { name: "כמעט ירי לעבר כוחותינו", value: 2 },
    { name: "כמעט ירי לעבר אזרחים", value: 2 },
    { name: "ירי כוחותינו לעבר כוחותינו", value: 3 },
    { name: "ירי כוחותינו לעבר אזרחים", value: 3 },
  ],
  roadsArr: [
    { name: `נפילת רק"מ/רכב ממוביל ללא התהפכות`, value: 2 },
    {
      name: "נסיעה ברכב ללא רישיון נהיגה מתאים או שאינו בתוקף",
      value: 2,
    },
    { name: "ביצוע נסיעה ללא אישור", value: 2 },
    {
      name: "הנהג או אחד הנוסעים נמצאו לא חגורים בחגורת בטיחות",
      value: 2,
    },
    { name: "התהפכות רכב", value: 3 },
  ],
  fireArr: [
    { name: "הופעל מטוס כיבוי", value: 2 },
    { name: "שריפה של 5 דונמים ויותר", value: 2 },
    { name: "שריפה כתוצאה מחומרים דליקים", value: 2 },
    { name: "שריפה עם לכודים", value: 3 },
  ],
  dangerousMaterialsArr: [
    { name: `נפילת מטען חומ"ס בעת הובלה/פריקה`, value: 2 },
    { name: "העפת גליל או בלון בלחץ", value: 3 },
  ],
  bigCarsArr: [
    { name: `התנגשות רק"מ עם רכב או ציוד`, value: 3 },
    { name: `דריסה של אדם/רכב ע"י רק"מ`, value: 3 },
    { name: `התהפכות רק"מ`, value: 3 },
  ],
  workArr: [
    { name: "התבקעות אבן משחזת/דסקית השחזה המונעת בכח מכני", value: 2 },
    {
      name: "התבקעות גלגל תנופה/ גלגל רצועה המוסבים בכח מכני",
      value: 2,
    },
    { name: "התבקעות מיכל או מסחט המוסבים בכח מכני", value: 3 },
    {
      name: "התמוטטות/נפילה של מעלית או של התא/הרציף במעלית, ניתוק כבל תיל, חבל/שרשרת המשמשים להרמה/להורדה של התא/הרציף במעלית",
      value: 3,
    },
    { name: "קריסה/התמוטטות של עגורן", value: 3 },
    {
      name: `נפילת עגורן, כננת או גלגלת וכל ניתוק כבל תיל, חבל או שרשרת המשמשים להרמה, להורדה או להובלה של מטען ע"י עגורן, כננת או גלגלת וכן קלקול המפסיק את פעולותיו של מנגנון ההפעלה או מנגנון הבטיחות של העגורן, הכננת, הגלגלת או חלקיהם`,
      value: 3,
    },
    {
      name: `התפוצצות/התבקעות של קולט (מיכל) גז, קולט אוויר או קולט קיטור או תערובת של גזים שונים וכן נוזלים או מוצקים שנוצרו 
בדחיסת גז`,
      value: 3,
    },
    {
      name: "התפוצצות או שריפה שגרמו נזק לבניין, למכונות, למתקנים, למכשירים חשמליים או לקווי מתח ותשתיות",
      value: 3,
    },
    {
      name: "נפילה מסולם, פיגום, במת הרמה, גג, תורן וכל אלמנט שגובהם מעל 2 מטרים",
      value: 3,
    },
    { name: "התמוטטות של פיגום או תבניות ליציקת בטון", value: 3 },
  ],
};
